import React from 'react';

class WriteToCSV extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.docus;
  }

  downloadCSV = () => {
    const csvData = this.convertToCSV(this.data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'output.csv'; // specify the desired file name
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  convertToCSV = (data) => {
    const header = Object.keys(data[0]);
    const rows = data.map(obj => header.map(field => JSON.stringify(obj[field])).join(','));
    return [header.join(','), ...rows].join('\n');
  }

  render() {
    return (
      <div>
        <button onClick={this.downloadCSV}>Download CSV</button>
      </div>
    );
  }
}

export default WriteToCSV;
