import React from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { sha256 } from "crypto-hash";
import { useCookies } from "react-cookie";

const Prompt = (props) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(true);
  const [error, setError] = React.useState({ type: "", message: "" });
  const [cookies, setCookie] = useCookies(["session"]);

  async function handleLogin() {
    const uhash = await sha256(username);
    const phash = await sha256(password);
    if (
      uhash === process.env.REACT_APP_USERNAME &&
      phash === process.env.REACT_APP_PASSWORD
    ) {
      props.setLogin(true);
      setModalOpen(false);
      setCookie("loggedIn", "true", { path: "/", maxAge: 1800 });
      setError({ type: "success", message: "Logged In Successfully" });
    } else {
      console.log("Invalid username/password");
      setError({ type: "error", message: "Invalid username/password" });
    }
  }
  setTimeout(() => {
    setError({ type: "", message: "" });
  }, 5000);
  return (
    <>
      <Dialog open={modalOpen}>
        <DialogTitle>
          <Typography variant="h2" align="center">
            Login
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            name="username"
            type="text"
            variant="outlined"
            label="Username"
            fullWidth
            required
            margin="dense"
            autoFocus
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            name="password"
            type="password"
            variant="outlined"
            label="Password"
            fullWidth
            margin="dense"
            required
            autoFocus
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogin}
            variant="outlined"
            color="warning"
            size="large"
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
      {error.type ? (
        <Snackbar open={true}>
          <Alert severity={error.type}>{error.message}</Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </>
  );
};

export default Prompt;
