import React, { useState } from "react";
import Prompt from "./components/Prompt";
import Response from "./components/Response";
import { useCookies } from "react-cookie";
import { useMediaQuery, Typography } from "@mui/material";
import Sheild from "./assets/mlsc.png";

function App() {
  const [login, isLogin] = useState(false);
  const [cookies] = useCookies(["session"]);
  (async () => {
    if (!login) {
      if (await cookies.loggedIn) {
        isLogin(true);
      } else {
        isLogin(false);
      }
    }
  })();
  const matches = useMediaQuery("(max-width:570px)");

  return (
    <div className="App">
      <div
        style={{
          position: "absolute",
          top: "1rem",
          left: "1rem",
        }}
      >
        <img src={Sheild} alt="sheild" width={matches ? "60px" : "100px"} />
      </div>
      <Typography
        variant={matches ? "h5" : "h3"}
        sx={{ marginTop: "1rem" }}
        align="center"
      >
        Responses
      </Typography>
      {login ? <Response /> : <Prompt setLogin={isLogin} />}
    </div>
  );
}

export default App;
