import React from "react";
import { Modal, Typography, Paper, Grid, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";

const ResponseModal = (props) => {
  const matches = useMediaQuery("(max-width:570px)");
  if (props.data) {
    return (
      <Modal
        open={props.isOpen}
        onClose={() => props.closeModal(false)}
        aria-labelledby="response-info"
        aria-describedby="full response of students"
      >
        <Container
          sx={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%,-50%)",
            boxShadow: 24,
            width: matches ? "90%" : "50%",
            height: "fit-content",
            backgroundColor: "#18181b",
            color: "white",
            padding: matches ? "0.25rem" : "1rem",
            margin: "0 auto",
          }}
          component={Paper}
        >
          <Typography variant="h5" align="center">
            {props.data.name}
          </Typography>
          <Grid
            container
            direction="column"
            align="left"
            sx={{ padding: "1rem", width: "90%" }}
          >
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Branch:
              </Typography>{" "}
              {props.data.branch}
            </Grid>
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Contact Number:
              </Typography>{" "}
              {props.data.contact_number}
            </Grid>
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Email:
              </Typography>{" "}
              {props.data.email}
            </Grid>
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Roll Number:
              </Typography>{" "}
              {props.data.roll_number}
            </Grid>
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Links:
              </Typography>{" "}
              {props.data.link}
            </Grid>
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Links1:
              </Typography>{" "}
              {props.data.link1}
            </Grid>
            <Grid item sx={{ padding: "0.15rem" }}>
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: "bold",
                }}
              >
                Introduction:
              </Typography>{" "}
              {props.data.introduction}
            </Grid>
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Skills(Tech):
              </Typography>{" "}
              {props.data.skills_tech}
            </Grid>
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Skills(Non-Tech):
              </Typography>{" "}
              {props.data.skills_nontech}
            </Grid>
            <Grid item sx={{ padding: "0.2rem" }}>
              <Typography sx={{ display: "inline-block", fontWeight: "bold" }}>
                Why MLSC?:
              </Typography>{" "}
              {props.data.whymlsc}
            </Grid>
          </Grid>
        </Container>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default ResponseModal;
