import {
  Container,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Snackbar,
  Alert,
  TableBody,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ResponseModal from "./Modal";
import { Client, Databases, Query } from "appwrite";
import WriteToCSV from "./download";

const Response = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [info, setInfo] = useState({ present: false, type: "", message: "" });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const client = new Client();

  const databases = new Databases(client);

  useEffect(() => {
    client
          .setEndpoint('https://cloud.appwrite.io/v1') // Your API Endpoint
          .setProject(process.env.REACT_APP_PROJECTID);
    try {
      async function fetchAllPages(databaseId, collectionId, cursor = null, allDocuments = []) {
        setLoading(true);
        const pageSize = 25;
    
        // Create a query to limit the number of documents and specify the cursor
        const query = [
            Query.limit(pageSize),
        ];
    
        if (cursor) {
            query.push(Query.cursorAfter(cursor));
        }
    
        // Fetch the current page
        const page = await databases.listDocuments(databaseId, collectionId, query);
    
        // Concatenate the current page's documents to the result
        allDocuments = allDocuments.concat(page.documents);
    
        // If there are more pages, continue fetching
        if (page.documents.length === pageSize) {
            // Get the cursor for the next page
            const nextCursor = page.documents[page.documents.length - 1].$id;
            return fetchAllPages(databaseId, collectionId, nextCursor, allDocuments);
        } else {
            // No more pages, return all documents
            setLoading(false);
            return allDocuments;
        }
    }
    
    // Usage
    const databaseId = process.env.REACT_APP_DATAID;
    const collectionId = process.env.REACT_APP_COLLECTIONID;
    
    fetchAllPages(databaseId, collectionId)
        .then((allDocuments) => {
          setInfo({
            present: true,
            type: "success",
            message: "Data Loaded Successfully",
          });
          setData(allDocuments);
          setTotal(allDocuments.length);
        })
        .catch((error) => {
          console.log(error);
          setInfo({ present: true, type: "error", message: "Error fetching data" });
        });
    } catch (e) {
      console.log(e);
      setInfo({ present: true, type: "error", message: "Error fetching data" });
    }
    setTimeout(() => {
      setInfo({ present: false, type: "", message: "" });
    }, 3000);
  }, []);

  function openFullResponse(response) {
    if (data) {
      setModalOpen(true);
      setModalData(response);
    } else return;
  }

  if (loading) {
    return (
      <Typography align="center" sx={{ marginTop: "1rem" }}>
        Aapka data raaste mei hai <br/>
        Kripya, thoda intezaar karein...
      </Typography>
    );
  }

  return (
    <Box>
      <Container>
        <Typography align="center" sx={{ marginTop: "1rem" }}>
          Total Responses: <strong>{total}</strong>
        </Typography>
        <WriteToCSV docus={data} />
        <TableContainer
          component={Paper}
          sx={{ marginTop: "2rem", borderRadius: "15px", marginBottom: "2rem" }}
        >
          <Table
            sx={{ minWidth: 650, borderRadius: "15px" }}
            size="small"
            aria-label="Response Table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#002984",
                    color: "#eee",
                    padding: "1rem",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#002984",
                    color: "#eee",
                    padding: "1rem",
                  }}
                  align="left"
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#002984",
                    color: "#eee",
                    padding: "1rem",
                  }}
                  align="left"
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#002984",
                    color: "#eee",
                    padding: "1rem",
                  }}
                  align="left"
                >
                  Branch
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((response, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    hover={true}
                  >
                    <TableCell
                      sx={{ padding: "0.75rem" }}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={() => {
                          openFullResponse(response);
                        }}
                      >
                        {index + 1}
                      </Button>
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem" }} align="left">
                      {response.name}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem" }} align="left">
                      {response.email}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem" }} align="left">
                      {response.branch}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {info.present ? (
        <Snackbar open={info.present}>
          <Alert severity={info.type}>{info.message}</Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <ResponseModal
        isOpen={modalOpen}
        data={modalData}
        closeModal={setModalOpen}
      />
    </Box>
  );
};

export default Response;